<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-7">
                <h5 class="mt-2">Novedades en Ruta</h5>
              </div>
              <div class="col-sm-5">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Hidrocarburos">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">HSE</li>
                  <li class="breadcrumb-item active">Novedades en Ruta</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="card col-md-9">
                <div class="card-header p-1 m-1">
                  <div class="row">
                    <div class="col-md-1">
                      <div class="form-group">
                        <label>Viaje</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="# Viaje"
                          v-model="filters.n_viaje"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="">Guía</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="# Guía"
                          v-model="filters.n_guia"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="">Vehículo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="Placa"
                          v-model="filters.placa"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="">Conductor</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="Nombre"
                          v-model="filters.n_conductor"
                        />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="">Empresa</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="empresa"
                          placeholder="Razon social"
                          label="razon_social"
                          :options="listasForms.empresas"
                          @input="seleccionarEmpresa()"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label class="form-label select-label"
                          >Tipo Novedad</label
                        >
                        <select
                          class="form-control form-control-sm"
                          v-model="filters.tipo_novedades"
                          @change="changeTipo()"
                        >
                          <option value="">Seleccione...</option>
                          <option value="1">GPS</option>
                          <option value="2">CALCULO RUTAS</option>
                          <option value="3">INSPECCIONES</option>
                          <option value="4">GEOCERCAS</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4" v-if="filters.tipo_novedades == 1">
                      <div class="form-group">
                        <label class="form-label select-label">Novedad</label>
                        <v-select
                          multiple
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          @input="selectNovedades()"
                          placeholder="Novedades"
                          class="form-control form-control-sm p-0"
                          v-model="listasForms.novedades"
                          label="nombre"
                          :options="[
                            { nombre: 'Exceso de velocidad', id: 5 },
                            { nombre: 'Aceleración Brusca', id: 7 },
                            { nombre: 'Frenada Brusca', id: 8 },
                            { nombre: 'Curva Brusca', id: 9 },
                            { nombre: 'Maltrato Suspension', id: 10 },
                            { nombre: 'Alerta Impacto', id: 11 },
                            { nombre: 'Botón Emergencia', id: 12 },
                          ]"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div class="col-md-4" v-if="filters.tipo_novedades == 2">
                      <div class="form-group">
                        <label class="form-label select-label">Novedad</label>
                        <v-select
                          multiple
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          @input="selectNovedades()"
                          placeholder="Novedades"
                          class="form-control form-control-sm p-0"
                          v-model="listasForms.novedades"
                          label="nombre"
                          :options="[
                            { nombre: 'Salida de ruta', id: 1 },
                            { nombre: 'Exceso de velocidad', id: 5 },
                          ]"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div
                      class="col-md-3"
                      v-if="listasForms.relevancias.length > 0"
                    >
                      <div class="form-group">
                        <label class="form-label select-label"
                          >Relevancia</label
                        >
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          multiple
                          v-model="relevancias"
                          @input="selectRelevancias()"
                          placeholder="Relevancias"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.relevancias"
                          :filterable="true"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <button
                        type="button"
                        class="btn btn-sm bg-navy mt-4"
                        @click="filtersChanges(1)"
                      >
                        <i class="fas fa-sync-alt"> </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="card-body pl-0 pr-0 pt-2 ml-0 mr-0 mt-2"
                  v-if="flag == false"
                >
                  <div class="col-md-12">
                    <vue-google-heatmap
                      :initial-zoom="zoom"
                      :lat="getCenterMap().lat"
                      :lng="getCenterMap().lng"
                      :points="points"
                      style="width: 100%; height: 650px"
                      :options="infoOptions"
                      ref="map"
                    />
                  </div>
                </div>
                <div
                  class="card-body pl-0 pr-0 pt-2 ml-0 mr-0 mt-2"
                  v-else-if="flag == true"
                >
                  <gmap-map
                    :center="center"
                    :zoom="zoom"
                    style="width: 100%; height: 650px"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      :icon="m.icon"
                      :clickable="true"
                      :draggable="false"
                      @click="toggleInfoWindow(m, m.id, m.info)"
                    ></gmap-marker>
                    <gmap-info-window
                      :options="infoOptions"
                      :position="infoWindowPos"
                      :opened="infoWinOpen"
                      @closeclick="infoWinOpen = false"
                    >
                      <div v-html="infoContent"></div>
                    </gmap-info-window>
                    <gmap-polygon
                      v-for="poligono in poligonos"
                      :options="{ fillColor: poligono.color }"
                      :key="poligono.id"
                      :paths="poligono.path"
                      :editable="false"
                      :draggable="false"
                      @click="
                        toggleInfoWindowPoli(
                          poligono,
                          poligono.id,
                          poligono.info
                        )
                      "
                    />
                    <gmap-circle
                      v-for="circular in circunferencias"
                      :options="{ fillColor: circular.color }"
                      :key="circular.id"
                      :center="circular.center"
                      :radius="circular.radius"
                      :info="circular.info"
                      @click="toggleInfoWindowCir(circular.id, circular.info)"
                    ></gmap-circle>
                  </gmap-map>
                </div>
              </div>
              <div class="col-md-3 table-responsive" style="max-height: 800px">
                <div class="timeline">
                  <div class="time-label">
                    <span class="bg-primary text-white"
                      >Ultimos 20 Minutos</span
                    >
                    <span
                      class="bg-info text-white m-2"
                      style="cursor: pointer"
                      @click="mapaCalor()"
                      >Mapa de calor</span
                    >
                  </div>
                  <div v-for="novedad in novedades" :key="novedad.id">
                    <i
                      :class="
                        novedad.relevancia == 1
                          ? 'fas fa-exclamation-triangle bg-warning'
                          : novedad.relevancia == 2
                          ? 'fas fa-first-aid bg-danger'
                          : 'bg-secondary'
                      "
                      style="cursor: pointer"
                      @click="mapaNovedad(novedad)"
                    ></i>
                    <div class="timeline-item">
                      <span class="time"
                        ><i class="fas fa-clock"></i>
                        {{ novedad.fecha_novedad }}</span
                      >
                      <h3 class="timeline-header">
                        <br />
                      </h3>
                      <div class="timeline-body">
                        <h3 style="font-size: 14px">
                          <b>Tipo Novedad: </b>
                          {{
                            novedad.tipo_novedad == 1
                              ? "GPS"
                              : novedad.tipo_novedad == 2
                              ? "CALCULO RUTAS"
                              : novedad.tipo_novedad == 3
                              ? "INSPECCIONES"
                              : "GEOCERCAS"
                          }}
                        </h3>
                        <h3
                          style="font-size: 14px"
                          v-if="novedad.tipo_novedad == 1"
                        >
                          <b>Novedad: </b>
                          <span class="badge badge-success">{{
                            novedad.tipo_subnovedad == 5
                              ? "Exceso de velocidad"
                              : novedad.tipo_subnovedad == 7
                              ? "Aceleración Brusca"
                              : novedad.tipo_subnovedad == 8
                              ? "Frenada Brusca"
                              : novedad.tipo_subnovedad == 9
                              ? "Curva Brusca"
                              : novedad.tipo_subnovedad == 10
                              ? "Maltrato Suspension"
                              : novedad.tipo_subnovedad == 11
                              ? "Alerta Impacto"
                              : novedad.tipo_subnovedad == 12
                              ? "Botón Emergencia"
                              : ""
                          }}</span>
                        </h3>
                        <h3
                          style="font-size: 14px"
                          v-if="novedad.tipo_novedad == 2"
                        >
                          <b>Novedad: </b>
                          <span class="badge badge-success">{{
                            novedad.tipo_subnovedad == 5
                              ? "Exceso de velocidad"
                              : novedad.tipo_subnovedad == 1
                              ? "Salida de ruta"
                              : ""
                          }}</span>
                        </h3>
                        <h3 style="font-size: 14px" v-else>
                          <b>Novedad: </b>
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Relevancia: </b>
                          <span
                            :class="
                              novedad.relevancia == 1
                                ? 'badge badge-warning'
                                : 'badge badge-danger'
                            "
                            >{{ novedad.nRelevancia }}</span
                          >
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Viaje: </b>
                          <button
                            type="button"
                            class="btn btn-sm btn-default py-0"
                            data-toggle="modal"
                            data-target="#modal-form-detViaje"
                            @click="
                              llenarModalDetViaje(
                                novedad.viaje_id,
                                novedad.viaje.tipo_operacion
                              )
                            "
                          >
                            {{ novedad.viaje_id }}
                          </button>
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Vehículo: </b> {{ novedad.vehiculo.placa }} -
                          {{ novedad.vehiculo.tipo_vehiculo.nombre }}
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Conductor: </b>
                          {{ novedad.conductor.nombres }}
                          {{ novedad.conductor.apellidos }}
                          <em style="font-size: 11px"
                            ><b>CC:</b>
                            {{ novedad.conductor.numero_documento }}</em
                          >
                          <em style="font-size: 11px">
                            <b> CEL:</b>
                            {{ novedad.conductor.celular_principal }}</em
                          >
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Empresa: </b>
                          {{ novedad.viaje.transportadora.razon_social }}
                        </h3>
                        <h3 style="font-size: 14px">
                          <span class="badge bg-navy" style="font-size: 9px"
                            >Latitud: {{ novedad.latitud }}</span
                          >
                          <span
                            class="badge bg-indigo ml-1"
                            style="font-size: 9px"
                            >Longitud: {{ novedad.longitud }}</span
                          >
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Direccion: </b>
                          {{ novedad.direccion }}
                        </h3>
                        <h3 style="font-size: 14px">
                          <b>Velocidad: </b>
                          {{ novedad.velocidad }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="fas fa-clock bg-gray"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card-body p-0">
              <table
                class="
                  table table-bordered table-striped table-hover table-sm
                  text-xs
                "
              >
                <thead class="bg-gray text-center">
                  <tr>
                    <th>
                      Viaje
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.viaje_id"
                        placeholder="#Viaje"
                        @keyup.enter="filtersChanges(1)"
                      />
                    </th>
                    <th>
                      Vehículo
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.vehiculo"
                        placeholder="Placa"
                        @keyup.enter="filtersChanges(1)"
                      />
                    </th>
                    <th>
                      Conductor
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.conductor"
                        placeholder="Nombre"
                        @keyup.enter="filtersChanges(1)"
                      />
                    </th>
                    <th>
                      Tipo Novedad
                      <select
                        class="form-control form-control-sm"
                        v-model="filters.tip_novedades"
                        @keyup.enter="filtersChanges(1)"
                      >
                        <option value="1">GPS</option>
                        <option value="2">CALCULO RUTAS</option>
                        <option value="3">INSPECCIONES</option>
                        <option value="4">GEOCERCAS</option>
                      </select>
                    </th>
                    <th>Novedad</th>
                    <th>Latitud</th>
                    <th>Longitud</th>
                    <th>
                      Fecha Novedad
                      <input
                        type="date"
                        v-b-popover.hover.left="'Fecha Expedicion'"
                        class="form-control form-control-sm"
                        v-model="filters.fecha_novedad"
                        placeholder="Expedición Guia"
                        @keyup.enter="filtersChanges(1)"
                      />
                    </th>
                    <th>
                      Relevancia
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        multiple
                        v-model="relevancia"
                        @input="selectRelevancia()"
                        placeholder="Relevancias"
                        label="descripcion"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.relevancias"
                        :filterable="true"
                      ></v-select>
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr v-for="nov in novedadesPag.data" :key="nov.id">
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-default py-0"
                        data-toggle="modal"
                        data-target="#modal-form-detViaje"
                        @click="
                          llenarModalDetViaje(
                            nov.viaje_id,
                            nov.viaje.tipo_operacion
                          )
                        "
                      >
                        {{ nov.viaje_id }}
                      </button>
                    </td>
                    <td>
                      {{ nov.vehiculo.placa }} -
                      <span class="badge badge-dark">{{
                        nov.vehiculo.tipo_vehiculo.nombre
                      }}</span>
                    </td>
                    <td>
                      {{ nov.conductor.nombres }}
                      {{ nov.conductor.apellidos }} -

                      <span class="badge badge-info">{{
                        nov.conductor.numero_documento
                      }}</span>
                    </td>
                    <td>
                      {{
                        nov.tipo_novedad == 1
                          ? "GPS"
                          : nov.tipo_novedad == 2
                          ? "CALCULO RUTAS"
                          : nov.tipo_novedad == 3
                          ? "INSPECCIONES"
                          : "GEOCERCAS"
                      }}
                    </td>
                    <td>
                      <span class="badge badge-success">{{
                        nov.tipo_subnovedad == 5
                          ? "Exceso de velocidad"
                          : nov.tipo_subnovedad == 7
                          ? "Aceleración Brusca"
                          : nov.tipo_subnovedad == 8
                          ? "Frenada Brusca"
                          : nov.tipo_subnovedad == 9
                          ? "Curva Brusca"
                          : nov.tipo_subnovedad == 10
                          ? "Maltrato Suspension"
                          : nov.tipo_subnovedad == 11
                          ? "Alerta Impacto"
                          : nov.tipo_subnovedad == 12
                          ? "Botón Emergencia"
                          : ""
                      }}</span>
                    </td>
                    <td>{{ nov.latitud }}</td>
                    <td>{{ nov.longitud }}</td>
                    <td>{{ nov.fecha_novedad }}</td>
                    <td>
                      <span
                        :class="
                          nov.relevancia == 1
                            ? 'badge badge-warning'
                            : 'badge badge-danger'
                        "
                        >{{ nov.nRelevancia }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="float-left" v-if="novedadesPag.total">
                <p>
                  Mostrando del <b>{{ novedadesPag.from }}</b> al
                  <b>{{ novedadesPag.to }}</b> de un total:
                  <b>{{ novedadesPag.total }}</b> Registros
                </p>
              </div>
              <div class="float-left" v-else>
                <p>
                  <span class="badge badge-danger"
                    >No hay registros para mostrar</span
                  >
                </p>
              </div>
              <pagination
                :data="novedadesPag"
                @pagination-change-page="getNovedades"
                :limit="10"
                align="right"
              >
              </pagination>
            </div>
          </div>
        </section>
        <ViajesDetViaje ref="ViajesDetViaje" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import moment from "moment";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import ViajesDetViaje from "../viajes/ViajesDetViaje";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "NovedadesRutaIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    ViajesDetViaje,
  },
  directives: {
    "b-popover": VBPopover,
  },
  data() {
    return {
      filters: {
        tipo_novedades: null,
        novedades: [],
        relevancias: [],
        relevancia: [],
        viaje_id: null,
        vehiculo: null,
        conductor: null,
        fecha_novedad: null,
      },
      flag: false,
      center: {},
      zoom: 6,
      cargando: false,
      points: [],
      novedades: [],
      novedadesPag: {},
      locations: {},
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      relevancias: null,
      relevancia: null,
      empresa: null,
      markers: [],
      poligonos: [],
      circunferencias: [],
      infoContent: "",
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      listasForms: {
        relevancias: [],
        novedades: [],
        empresas: [],
      },
      polling: null,
      page: "",
    };
  },

  methods: {
    llenarModalDetViaje(viaje, tipo_control) {
      this.$refs.ViajesDetViaje.llenar_modal_detViaje(viaje, tipo_control);
    },

    playSound(sound) {
      var audio = new Audio(require("@/assets/" + sound));
      audio.play();
    },

    getCenterMap(novedad = null) {
      if (novedad) {
        return {
          lat: parseFloat(novedad.latitud),
          lng: parseFloat(novedad.longitud),
        };
      } else {
        return {
          lat: parseFloat(4.651371),
          lng: parseFloat(-74.071509),
        };
      }
    },

    mapaNovedad(novedad) {
      this.center = {};
      this.markers = [];
      this.infoWinOpen = false;
      this.showGeocercas();
      if (novedad) {
        this.flag = true;
        this.center = {
          lat: parseFloat(novedad.latitud),
          lng: parseFloat(novedad.longitud),
        };
        // Datos del origen
        let ntipoSubnovedad =
          novedad.tipo_subnovedad == 5
            ? "Exceso de velocidad"
            : novedad.tipo_subnovedad == 7
            ? "Aceleración Brusca"
            : novedad.tipo_subnovedad == 8
            ? "Frenada Brusca"
            : novedad.tipo_subnovedad == 9
            ? "Curva Brusca"
            : novedad.tipo_subnovedad == 10
            ? "Maltrato Suspension"
            : novedad.tipo_subnovedad == 11
            ? "Alerta Impacto"
            : novedad.tipo_subnovedad == 12
            ? "Botón Emergencia"
            : "";

        let ntipoNovedad =
          novedad.tipo_novedad == 1
            ? "GPS"
            : novedad.tipo_novedad == 2
            ? "CALCULO RUTAS"
            : novedad.tipo_novedad == 3
            ? "INSPECCIONES"
            : "GEOCERCAS";

        this.markers.push({
          position: this.center,
          icon: "/img/icon_punto_g_ok.png",
          info:
            "<div class='wrapper'><h3 style='font-size:15px' class='text-center'>Vehículo: " +
            novedad.vehiculo.placa +
            "</h3><hr><b>Fecha Novedad: </b> " +
            novedad.fecha_novedad +
            "<br><b>Empresa: </b> " +
            novedad.viaje.transportadora.razon_social +
            "<br><b>Conductor: </b> " +
            novedad.conductor.nombres +
            " " +
            novedad.conductor.apellidos +
            "<br><b>Tipo Novedad: </b> " +
            "<span class='badge badge-info'>" +
            ntipoNovedad +
            "</span>" +
            "<br><b>Novedad: </b> " +
            "<span class='badge badge-success'>" +
            ntipoSubnovedad +
            "</span>" +
            "<br><b>Relevancia: </b> " +
            novedad.nRelevancia +
            "<br> <b>Velocidad: </b> " +
            novedad.velocidad +
            " Km/h" +
            "<br> <b>Direccion:</b> " +
            novedad.direccion +
            "<br> <b>Viaje:</b> " +
            novedad.viaje_id +
            "<br> <b>Longitud:</b> " +
            novedad.longitud +
            "<br> <b>Latitud:</b> " +
            novedad.latitud +
            "</div>",
          id: novedad.id,
        });
        this.zoom = 10;
        this.$swal({
          icon: "success",
          title: "En el mapa se muestra la ubicacion de la novedad",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },

    mapaCalor() {
      this.cargando = true;
      this.flag = false;
      this.getNovedades(this.page);
      this.$swal({
        icon: "success",
        title: "Se renderiza al mapa de calor.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
      this.cargando = false;
    },

    async filtersChanges() {
      this.cargando = true;
      await this.getNovedades(this.page);
      this.cargando = false;
    },

    async getNovedades(page) {
      this.page = page;
      let me = this;
      await axios
        .get("/api/hidrocarburos/getNovedades?page=" + page, {
          params: this.filters,
        })
        .then(async (response) => {
          let novedades = [];
          novedades = response.data;
          me.novedades = novedades.novedades;
          me.novedadesPag = novedades.novedadesPag;
          me.renderNovedades();
          me.asignPoints();
        });
    },

    renderNovedades() {
      let horaAnt = moment()
        .subtract(21, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");

      for (let i = 0; i < this.novedades.length; i++) {
        let horaAct = new Date(this.novedades[i].created_at);
        horaAct = moment(horaAct).format("YYYY-MM-DD HH:mm:ss");
        if (horaAct >= horaAnt) {
          let sound = null;
          if (this.novedades[i].relevancia == 1) {
            sound = "advertencia.mp3";
          } else {
            sound = "emergencia.mp3";
          }
          this.playSound(sound);
        }
      }
    },

    asignPoints() {
      this.points = [];
      for (let i = 0; i < this.novedades.length; i++) {
        this.points.push({
          lat: parseFloat(this.novedades[i].latitud),
          lng: parseFloat(this.novedades[i].longitud),
        });
      }
    },

    selectNovedades() {
      this.filters.novedades = this.listasForms.novedades.map((e) => e.id);
    },

    selectRelevancias() {
      this.filters.relevancias = this.relevancias.map((e) => e.numeracion);
    },

    selectRelevancia() {
      this.filters.relevancia = this.relevancia.map((e) => e.numeracion);
    },

    seleccionarEmpresa() {
      this.filters.empresa_id = this.empresa ? this.empresa.id : null;
    },

    getTipoNovedades() {
      axios.get("/api/lista/149").then((response) => {
        this.listasForms.relevancias = response.data;
      });
    },

    buscarEmpresas() {
      let me = this;
      const url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    changeTipo() {
      this.filters.novedades = [];
    },

    async centerNovedad(novedad) {
      this.lat = parseFloat(novedad.latitud);
      this.lng = parseFloat(novedad.longitud);
      this.zoom = 15;
    },

    pollData() {
      this.polling = setInterval(() => {
        this.getNovedades(this.page);
      }, 20000);
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    async showGeocercas() {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/mapas/showGeocercas")
        .then((response) => {
          this.cargando = false;
          this.locations = response.data.gps_fin;
          this.locations.geocerca_poli.forEach((geocerca) => {
            if (geocerca.puntos.length > 0) {
              let puntos = [];
              geocerca.puntos.forEach((punto) => {
                puntos.push({
                  lng: parseFloat(punto.longitud),
                  lat: parseFloat(punto.latitud),
                });
              });

              this.poligonos.push({
                id: geocerca.id,
                name: geocerca.desc,
                path: puntos,
                info: geocerca.ventana,
                color: geocerca.color,
              });
            }
          });
          for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
            if (this.locations.geocerca_cir[j]["lat"] !== "") {
              this.circunferencias.push({
                center: {
                  lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                  lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                },
                radius:
                  parseFloat(this.locations.geocerca_cir[j]["radio"]) * 1000,
                info: this.locations.geocerca_cir[j]["ventana"],
              });
            }
          }
        })
        .catch(function(error) {
          this.cargando = false;
        });
    },
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  created() {
    this.pollData();
  },

  mounted() {
    this.getNovedades(1);
    this.getTipoNovedades();
    this.buscarEmpresas();
  },
};
</script>
